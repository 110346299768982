import type { MutationTree } from 'vuex';
export const state = () => ({
  url: ''
});

export type UrlExtraction = ReturnType<typeof state>;
export const mutations: MutationTree<UrlExtraction> = {
  SET_URL: (state: UrlExtraction, url: string) => {
    state.url = url;
  }
};
