import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import type { BasicEvaluationList, BasicEvaluationStep, UpdateBasicEvaluation } from '~/entities/basic-evaluation';

@Module({
  stateFactory: true,
  name: 'basicData',
  namespaced: true
})
export default class BasicData extends VuexModule implements BasicEvaluationList, UpdateBasicEvaluation {
  evalId? = '';
  stepOne?: BasicEvaluationStep = {};
  stepTwo?: BasicEvaluationStep = {};
  stepThree?: BasicEvaluationStep = {};
  stepFour?: BasicEvaluationStep = {};
  low?: number = 0;
  up?: number = 0;
  point?: number = 0;

  @Mutation
  setBasicData(data: BasicEvaluationList) {
    this.evalId = data.evalId;
    this.stepOne = data.stepOne;
    this.stepTwo = data.stepTwo;
    this.stepThree = data.stepThree;
    this.stepFour = data.stepFour;
  }

  @Mutation
  setEvaluation(data: UpdateBasicEvaluation) {
    this.low = data.low;
    this.up = data.up;
    this.point = data.point;
  }

  get chartData() {
    return {
      evalId: this.evalId,
      stepOne: this.stepOne,
      stepTwo: this.stepTwo,
      stepThree: this.stepThree,
      stepFour: this.stepFour
    };
  }

  get previewData() {
    return {
      low: this.low,
      up: this.up,
      point: this.point
    };
  }
}
