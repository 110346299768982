import type { MutationTree } from 'vuex';

export const state = () => ({
  locales: ['at', 'en', 'de', 'ch'],
  locale: 'default'
});

export type Ii18nState = ReturnType<typeof state>;

export const mutations: MutationTree<Ii18nState> = {
  SET_LANG: (state, newLocale: string) => {
    const finalLocale = newLocale === 'default' ? 'at' : newLocale;
    state.locale = finalLocale;
  }
};
