import { Module, VuexModule } from 'nuxt-property-decorator';
import { Mutation } from 'vuex-module-decorators';
import {
  ListingTypeEstateDetailTypeEnum,
  ListingTypeEstateTypeEnum
} from '~/schema/api';

export type GeoLocation = {
  latitude: number;
  longitude: number;
  route: string;
  locality: string;
  // eslint-disable-next-line camelcase
  streetNumber: string;
  // eslint-disable-next-line camelcase
  administrative_area_level_2: string;
  // eslint-disable-next-line camelcase
  administrative_area_level_1: string;
  country: string;
  // eslint-disable-next-line camelcase
  postal_code: string;
  name: string;
  // eslint-disable-next-line camelcase
  place_id: string;
};

@Module({
  stateFactory: true,
  name: 'propertyTypeSearch',
  namespaced: true
})
export default class PropertyTypeSearchStore extends VuexModule {
  step = 0;
  newEvaluation = false;
  doneEvaluation = false;
  landingPage = false;
  address = '';
  addressObject: Partial<GeoLocation> = {};

  propertyType: ListingTypeEstateTypeEnum = ListingTypeEstateTypeEnum.APARTMENT;
  propertyCategory: ListingTypeEstateDetailTypeEnum =
    ListingTypeEstateDetailTypeEnum.FLOOR_APARTMENT;

  @Mutation
  setNewEvaluation(newEvaluation: boolean) {
    this.newEvaluation = newEvaluation;
  }

  @Mutation
  setLandingPage(val: boolean) {
    this.landingPage = val;
  }

  @Mutation
  setDoneEvaluation(doneEvaluation: boolean) {
    this.doneEvaluation = doneEvaluation;
  }

  @Mutation
  setStep(step: number) {
    this.step = step;
  }

  @Mutation
  setAddress(address: string) {
    this.address = address;
  }

  @Mutation
  removeAddress() {
    this.address = '';
    this.addressObject = {};
  }

  @Mutation
  setAddressObject(addressObject: Partial<GeoLocation>) {
    this.addressObject = addressObject;
  }

  @Mutation
  setPropertyType(propertyType: ListingTypeEstateTypeEnum) {
    this.propertyType = propertyType;
  }

  @Mutation
  setPropertyCategory(propertyCategory: ListingTypeEstateDetailTypeEnum) {
    this.propertyCategory = propertyCategory;
  }

  @Mutation
  resetStep() {
    this.step = 0;
  }
}

// export const state = () => ({
//   step: 0,
//   NewEvaluation: false,
//   doneEvaluation: false,
//   address: '',
//   addressObject: {} as any,
//   propertyType: '' as PropertyType,
//   propertyCategory: '' as PropertyCategory,
//   usableArea: 10, // apartment
//   floorArea: 10, // house
//   livingSpace: 10,
//   roomsNumber: 1,
//   constructionYear: 1900
// });
