import { Plugin } from '@nuxt/types';

const checkVersion: Plugin = () => {
  const version = process.env.VERSION;
  if (version) {
    if (localStorage.getItem('version') !== version) {
      // Check if version is different from the one set on localStorage
      // If version is different, clear localStorage and set the new version and refresh the page
      localStorage.clear();
      localStorage.setItem('version', version);
    }
  }
};

export default checkVersion;
