import { Plugin } from '@nuxt/types';
import { getModule } from 'vuex-module-decorators';
import { initializeAxios } from '~/utils/api';
import UserProfile from '~/store/userProfile';

const accessor: Plugin = ({ $axios, app, $fire }) => {
  const userProfileStore = getModule(UserProfile, app.store);
  const axios = $axios.create({
    baseURL: app.$config.API_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json'
    }
  });

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;

      if (error.response?.status === 401 && !originalRequest._retry) {
        originalRequest._retry = true;

        // Get the token using the provided context, not 'this'
        const newToken = await $fire.auth.currentUser?.getIdToken(true);

        originalRequest.headers['Authorization'] = 'Bearer ' + newToken;

        // Use 'axios' instead of 'instance'
        return axios(originalRequest);
      } else if (
        error.response?.status === 401 &&
        originalRequest._retry &&
        (!window.location.pathname.includes('login') &&
          !window.location.pathname.includes('register') &&
          !window.location.pathname.includes('objekt-radar'))
      ) {
        await $fire.auth.signOut();
        userProfileStore.SIGN_OUT();
        app.router?.push('/at/app/login');
      }

      return Promise.reject(error);
    }
  );

  initializeAxios(axios);
};

export default accessor;
