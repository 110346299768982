import { Reflection } from '@abraham/reflection';

const root = typeof global === 'object'
  ? global
  : typeof self === 'object'
    ? self
    : typeof this === 'object'
      ? this
      // eslint-disable-next-line no-new-func
      : Function('return this;')();

root.Reflect = Object.assign(Reflect, Reflection)

export default () => {
  throw new Error('This is hack for correct working reflect metadata on server');
};
