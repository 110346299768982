
import { Component, Vue, Prop } from 'nuxt-property-decorator';
import { Store } from '~/decorators';
import EvaluationsStore from '~/store/evaluations';
import UserProfile from '~/store/userProfile';
import PropertyTypeSearchStore from '~/store/propertyTypeSearch';

@Component
export default class UserProfileTooltip extends Vue {
  @Store() userProfile!: UserProfile;
  @Store() evaluationsStore!: EvaluationsStore;
  @Prop() user!: any;
  @Prop() lang!: string;
  @Prop() dashboard!: boolean;
  @Store() propertyTypeSearchStore!: PropertyTypeSearchStore;

  get avatar(): string {
    return this.userProfile?.profile?.avatar || '';
  }

  get firstName(): string {
    return this.userProfile?.profile?.firstName || '';
  }

  get lastName(): string {
    return this.userProfile?.profile?.lastName || '';
  }

  linkGenerator(link: string) {
    return `/${this.lang}${link}`;
  }

  get email(): string {
    return this.$fire.auth.currentUser?.email || '';
  }

  async logout() {
    await this.$fire.auth.signOut().then(() => {
      this.userProfile.SIGN_OUT();
      window.location.replace('https://ireen.com');
    });
  }

  private _goHome() {
    this.$router.push(`/${this.lang}/app/bewertung`);
  }

  auth = {
    title: {
      de: 'Abmelden',
      en: 'Sign Out'
    }
  };

  get bargains() {
    return {
      title: this.$i18n.t('navbar.user-profile.bargains.title'),
      link: this.$i18n.t('navbar.user-profile.bargains.link')
    };
  }

  items = [
    {
      title: {
        de: 'Übersicht',
        en: 'Dashboard'
      },
      link: {
        en: '/app/bewertung',
        de: '/app/bewertung'
      }
    },
    {
      title: {
        de: 'Konto Einstellungen',
        en: 'Account Settings'
      },
      link: {
        en: '/app/settings',
        de: '/app/einstellungen'
      }
    },
    {
      title: {
        en: 'Purchases',
        de: 'Einkäufe'
      },
      link: {
        en: '/app/settings#purchases',
        de: '/app/einstellungen#purchases'
      }
    }
  ];

  resetStoreToDefault(link: string) {
    link === '/en/app/bewertung' &&
      this.propertyTypeSearchStore.setNewEvaluation(false);
  }
}
