import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  name: 'peyment',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class Peyment extends VuexModule {
  priceList = [];
  peymentList = [];

  @Mutation
  setPriceList(val: any) {
    this.priceList = val;
  }

  @Mutation
  setPeymentList(val: any) {
    this.peymentList = val;
  }

  get getPeymentList() {
    return this.peymentList;
  }
}
