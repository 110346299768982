export default function ({ route, store, redirect }) {
  if (!store.$fire.auth.currentUser?.email) {
    if (route.path === '/at/app/bewertung') {
      redirect(`/at/app/loading?from=${route.fullPath}`)
    }
    if (route.path === '/at/app/objekt-radar') {
      redirect('/at/app/loading?from=/at/app/objekt-radar')
    }
  }
}
