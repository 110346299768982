import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Subscription, login, Details } from '~/entities/bargains';
import { data } from '~/schema/bargains';
@Module({
  name: 'bargains',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class Bargains extends VuexModule {
  details: Details = {};
  subscription: Subscription = {
    price_id: null,
    region: null,
    login: login.login,
    selected: false,
    id: 'price_1M3zWqHUXEYAV7fAHbWqv2k8',
    productId: 'prod_MnaiytUd1lxXP6',
    interval: 'month',
    intervalCount: 3,
    price: '360'
  };
  list?: Array<data> = [];

  @Mutation
  setSubscription(val: any) {
    this.subscription.id = val.id;
    this.subscription.productId = val.productId;
    this.subscription.interval = val.interval;
    this.subscription.intervalCount = val.intervalCount;
    this.subscription.price = val.price;
    this.subscription.region = val.region;
  }

  @Mutation
  setTransferType(val) {
    this.details.transfer_type = val;
  }

  @Mutation
  setBargainsDetailes(data: any) {
    this.details = data;
  }

  @Mutation
  setPriceId(data: string) {
    this.subscription.price_id = data;
  }

  @Mutation
  setRegion(data: string) {
    this.subscription.region = data;
  }

  @Mutation
  setTagToDetails(val: any) {
    this.details?.tags?.push(val);
  }

  @Mutation
  removeTagToDetails() {
    this.details?.tags = [];
  }

  @Mutation
  setSubscriptionSelect(val: boolean) {
    this.subscription.selected = val;
  }

  @Mutation
  addFave(id: string) {
    const item = this.list?.find((item) => item.object_id === id);
    item?.tags?.push('favorite');
  }

  @Mutation
  removeFave(id: string) {
    const item = this.list?.find((item) => item.object_id === id);
    item?.tags = [];
    if (this.details.object_id === item.object_id) {
      this.details?.tags = [];
    }
  }

  @Mutation
  setLoginType(val: login) {
    this.subscription.login = val;
  }

  @Mutation
  setList(data: data[] | undefined) {
    this.list = data;
  }

  @Mutation
  pushList(data: data[] | undefined) {
    this.list.push(...data);
  }

  get getList() {
    return this.list;
  }
}
