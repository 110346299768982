import { CategoryScale, Chart, LinearScale, LineController, LineElement, PointElement, Filler } from 'chart.js';

Chart.register(
  LineElement,
  LineController,
  CategoryScale,
  LinearScale,
  PointElement,
  Filler
);
