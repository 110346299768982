import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import {
  BargainsFilter,
  BuyRent,
  ObjectType,
  AlleAnzeigen,
  Sort,
  StrategyDropdown,
  conditionItems,
  ObjectTypeOptionApartment,
  ObjectTypeOptionHouse
} from '~/entities/bargains';
import type { pagination } from '~/entities/bargains';
import Moment from 'moment';
@Module({
  name: 'bargainsFilter',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class bargainsFiter extends VuexModule {
  scrollPosition: number = 0;
  strategyDropdown: [StrategyDropdown] = [];
  filter: BargainsFilter = {
    isAuction: false,
    showFilter: false,
    buyRent: BuyRent.Buy,
    objectType: ObjectType.All,
    condition: conditionItems.all,
    postal_code: [],
    region: [],
    hasOutsideArea: false,
    alleAnzeigen: AlleAnzeigen.All,
    objectTypeOption: ObjectTypeOptionHouse.All,
    sort: Sort.empfohleneZuerst,
    createdAt: undefined,
    fromSize: { from: undefined, to: undefined },
    fromRoom: { from: undefined, to: undefined },
    sizePlotArea: { from: undefined, to: undefined },
    price: { from: undefined, to: undefined },
    sizeOutsideArea: { from: undefined, to: undefined },
    typeYearBuilt: { from: undefined, to: undefined },
    populationGrowth: { from: undefined, to: undefined },
    unemploymentRate: { from: undefined, to: undefined },
    outdoorArea: false,
    rendite: undefined,
    cherryScore: undefined,
    exactAddressKnown: false,
    amElevator: false,
    sellerPrivateInsertion: null,
    faiv: false,
    total: 0,
    count: 0,
    page: 1,
    size: 20,
    loading: false
  };

  newFilter: string | null = null;

  selectedFilter: BargainsFilter | null = null;
  savedFilter: [BargainsFilter] | null = null;

  filterISdirty: boolean = false;

  get checkFilterIsDirty() {
    return (
      this.filter.exactAddressKnown === true ||
      this.filter.faiv === true ||
      this.filter.price.from ||
      this.filter.rendite ||
      this.filter.condition !== conditionItems.all ||
      this.filter.fromSize.from ||
      this.filter.fromSize.to ||
      this.filter.price.to ||
      this.filter.fromRoom.from ||
      this.filter.fromRoom.to ||
      this.filter.cherryScore ||
      this.filter.createdAt ||
      this.filter.sizePlotArea.from ||
      this.filter.hasOutsideArea ||
      this.filter.sizePlotArea.to ||
      this.filter.sizeOutsideArea.to ||
      this.filter.sizeOutsideArea.from ||
      this.filter.typeYearBuilt.from ||
      this.filter.typeYearBuilt.to ||
      this.filter.amElevator ||
      this.filter.postal_code.length < 0 ||
      this.filter.unemploymentRate.to ||
      this.filter.unemploymentRate.from ||
      this.filter.populationGrowth.from ||
      this.filter.isAuction ||
      this.filter.populationGrowth.to ||
      this.filter.sellerPrivateInsertion ||
      this.filter.objectTypeOption !== ObjectTypeOptionApartment.All ||
      this.filter.objectType !== ObjectType.All ||
      this.filter.alleAnzeigen !== AlleAnzeigen.All
    );
  }

  @Mutation
  seTfilterISdirty(val: boolean) {
    this.filterISdirty = val;
  }

  @Mutation
  setScroll(val) {
    this.scrollPosition = val;
  }

  get getScrollPosition() {
    return this.scrollPosition;
  }

  @Mutation
  setStrategyDropdown(value: StrategyDropdown) {
    this.strategyDropdown = value;
  }

  @Mutation
  setEmailNotification(val: boolean) {
    this.selectedFilter.email_notification.frequency = val;
  }

  @Mutation
  SetSelectedFilter(val) {
    this.selectedFilter = val;
    if (val) {
      const dateToDay = (val) => {
        const tileDate = Moment(val);
        const todayDate = Moment();
        const daysOnlineDiff = Moment.duration(todayDate.diff(tileDate));
        return Math.ceil(daysOnlineDiff.asDays()) - 1;
      };

      const revert = (state) => {
        state.map((item) => {
          for (const iterator in this.regiens) {
            if (this.regiens[iterator].hasOwnProperty(item)) {
              this.filter.region.push({
                region: iterator,
                [item]: this.regiens[iterator][item]
              });

              const findRegin = this.filter.region?.filter(
                (idx) =>
                  Object.keys(idx).length === 1 && idx.region === iterator
              );
              if (findRegin?.length === 0) {
                this.filter.region.push({
                  region: iterator
                });
              }
            }
          }
        });
      };

      const revertState = (state) => {
        state.map((item) => {
          const index = this.filter.region?.find(
            (reqItem) => reqItem.region === item
          );
          if (!index) {
            this.filter.region.push({
              region: item
            });
          }
        });
      };

      const checkSetAlleAnzeigen = () => {
        this.filter.alleAnzeigen = '';
        const region = this.filter?.region;
        region?.forEach((element) => {
          if (Object.keys(element).length === 1) {
            this.filter.alleAnzeigen += `${element.region}...`;
          } else {
            this.filter.alleAnzeigen += `${
              element[Object.keys(element)[0]]
            }...`;
          }
        });

        if (!this.filter?.region || this.filter?.region?.length === 0) {
          this.filter.alleAnzeigen = AlleAnzeigen.All;
        }

        if (!this.filter['sellerPrivateInsertion']) {
          this.filter.sellerPrivateInsertion = null;
        }

        if (!this.filter['isAuction']) {
          this.filter.isAuction = null;
        }
      };

      const object = val.query;
      for (const iterator of object) {
        switch (iterator.name) {
          case 'district':
            revert(iterator.value);
            break;
          case 'state':
            revertState(iterator.value);
            break;
          case 'created_at':
            this.filter.createdAt = dateToDay(iterator.value.from_value);
            break;
          case 'postal_code':
            this.filter.postal_code = iterator.value;
            break;
          case 'estate_type':
            this.filter.objectType = iterator.value;
            break;
          case 'condition':
            this.filter.condition = iterator.value;
            break;
          case 'price':
            this.filter.price.from = iterator.value.from_value;
            this.filter.price.to = iterator.value.to_value;
            break;
          case 'living_area':
            this.filter.fromSize.from = iterator.value.from_value;
            this.filter.fromSize.to = iterator.value.to_value;
            break;
          case 'price_return':
            this.filter.rendite = iterator.value.from_value;
            break;
          case 'cherry_score':
            this.filter.cherryScore = iterator.value.from_value;
            break;
          case 'is_auction_object':
            this.filter.isAuction =
              iterator.value === 'True'
                ? true
                : iterator.value === 'False'
                ? false
                : iterator.value;
            break;
          case 'seller_private_insertion':
            this.filter.sellerPrivateInsertion =
              iterator.value === 'True'
                ? true
                : iterator.value === 'False'
                ? false
                : null;
            break;
          case 'tag':
            this.filter.faiv = !!iterator.value;
            break;
          case 'estate_detail_type':
            this.filter.objectTypeOption = iterator.value;
            break;
          case 'size_plot_area':
            this.filter.sizePlotArea.from = iterator.value.from_value;
            this.filter.sizePlotArea.to = iterator.value.to_value;
            break;

          case 'type_year_built':
            this.filter.typeYearBuilt.from = iterator.value.from_value;
            this.filter.typeYearBuilt.to = iterator.value.to_value;
            break;
          case 'number_of_rooms':
            this.filter.fromRoom.from = iterator.value.from_value;
            this.filter.fromRoom.to = iterator.value.to_value;
            break;
          case 'has_outside_area':
            this.filter.hasOutsideArea = iterator.value;
            break;

          case 'has_exact_value':
            this.filter.exactAddressKnown = iterator.value;
            break;
          case 'am_elevator':
            this.filter.amElevator = iterator.value;
            break;
          case 'population_growth':
            this.filter.populationGrowth.from = iterator.value.from_value;
            this.filter.populationGrowth.to = iterator.value.to_value;
            break;
          case 'unemployment_rate':
            this.filter.unemploymentRate.from = iterator.value.from_value;
            this.filter.unemploymentRate.to = iterator.value.to_value;
            break;
        }
      }
      checkSetAlleAnzeigen();
    }
  }

  @Mutation
  SetSavedFilter(val: [BargainsFilter]) {
    this.savedFilter = val;
  }

  @Mutation
  setNewFilter(val: string) {
    this.newFilter = val;
  }

  @Mutation
  setRegionAsFilter(data: [{}]) {
    this.filter.region = data;
  }

  @Mutation
  setAlleAnzeigen(val: AlleAnzeigen) {
    this.filter.alleAnzeigen = val;
  }

  @Mutation
  setPostalCode(val: BargainsFilter['postal_code']) {
    this.filter.postal_code = val;
  }

  @Mutation
  setObjectTypeOptopn(val: ObjectTypeOptionHouse | ObjectTypeOptionApartment) {
    this.filter.objectTypeOption = val;
  }

  @Mutation
  setSort(val: Sort) {
    this.filter.sort = val;
  }

  @Mutation
  setFaiv(val: boolean) {
    this.filter.faiv = val;
  }

  @Mutation
  setCherryScore(val: number | undefined) {
    this.filter.cherryScore = val;
  }

  @Mutation
  setAmElevator(val: boolean) {
    this.filter.amElevator = val;
  }

  @Mutation
  setHasOutsideArea(val: boolean) {
    this.filter.hasOutsideArea = val;
  }

  @Mutation
  setSellerPrivateInsertion(val: boolean) {
    this.filter.sellerPrivateInsertion = val;
  }

  @Mutation
  setRendite(val: number | undefined) {
    this.filter.rendite = val;
  }

  @Mutation
  setShowFilter(val: boolean) {
    this.filter.showFilter = val;
  }

  @Mutation
  setIsAuction(val: boolean) {
    this.filter.isAuction = val;
  }

  @Mutation
  setCreatedAt(val: number | undefined) {
    this.filter.createdAt = val;
  }

  @Mutation
  setPage(val: number) {
    this.filter.page = val;
  }

  @Mutation
  setCondition(val: conditionItems) {
    this.filter.condition = val;
  }

  @Mutation
  setLoading(val: boolean) {
    this.filter.loading = val;
  }

  get filtersData() {
    return this.filter;
  }

  get getLoading() {
    return this.filter.loading;
  }

  @Mutation
  setPagination(data: pagination) {
    this.filter.count = data.count;
    this.filter.total = data.total;
    this.filter.page = data.page;
    this.filter.selected_region = data.selected_region;
  }

  @Mutation
  setRegion(region: BargainsFilter['region']) {
    this.filter.region = region;
  }

  @Mutation
  setExactAddressKnown(exactAddressKnown: boolean) {
    this.filter.exactAddressKnown = exactAddressKnown;
  }

  @Mutation
  setOutdoorArea(outdoorArea: boolean) {
    this.filter.outdoorArea = outdoorArea;
  }

  @Mutation
  setPopulationGrowthFrom(from: number | undefined) {
    this.filter.populationGrowth.from = from;
  }

  @Mutation
  setPopulationGrowthTo(to: number | undefined) {
    this.filter.populationGrowth.to = to;
  }

  @Mutation
  setTypeYearBuiltTo(to: number | undefined) {
    this.filter.typeYearBuilt.to = to;
  }

  @Mutation
  setUnemploymentRateFrom(from: number | undefined) {
    this.filter.unemploymentRate.from = from;
  }

  @Mutation
  setUnemploymentRateTo(to: number | undefined) {
    this.filter.unemploymentRate.to = to;
  }

  @Mutation
  setTypeYearBuiltFrom(from: number | undefined) {
    this.filter.typeYearBuilt.from = from;
  }

  @Mutation
  setSizeOutsideAreaTo(to: number | undefined) {
    this.filter.sizeOutsideArea.to = to;
  }

  @Mutation
  setSizeOutsideAreaFrom(from: number | undefined) {
    this.filter.sizeOutsideArea.from = from;
  }

  @Mutation
  setSizePlotAreaTo(to: number | undefined) {
    this.filter.sizePlotArea.to = to;
  }

  @Mutation
  setSizePlotAreaFrom(from: number | undefined) {
    this.filter.sizePlotArea.from = from;
  }

  @Mutation
  setFromRoomFrom(from: number | undefined) {
    this.filter.fromRoom.from = from;
  }

  @Mutation
  setFromRoomTo(to: number | undefined) {
    this.filter.fromRoom.to = to;
  }

  @Mutation
  setFromSizeFrom(from: number) {
    this.filter.fromSize.from = from;
  }

  @Mutation
  setPriceTo(to: number | undefined) {
    this.filter.price.to = to;
  }

  @Mutation
  setPriceFrom(from: number) {
    this.filter.price.from = from;
  }

  @Mutation
  setFromSizeTo(to: number) {
    this.filter.fromSize.to = to;
  }

  @Mutation
  setBuyRent(buyRent: BuyRent) {
    this.filter.buyRent = buyRent;
  }

  @Mutation
  setObjectType(objectType: ObjectType) {
    this.filter.objectType = objectType;
    this.filter.objectTypeOption = ObjectTypeOptionApartment.All;
  }

  regiens = {
    Burgenland: {
      101: 'Eisenstadt (Stadt)',
      102: 'Rust (Stadt)',
      103: 'Eisenstadt-Umgebung',
      104: 'Güssing',
      105: 'Jennersdorf',
      106: 'Mattersburg',
      107: 'Neusiedl am See',
      108: 'Oberpullendorf',
      109: 'Oberwart'
    },
    Kärnten: {
      201: 'Klagenfurt Stadt',
      202: 'Villach Stadt',
      203: 'Hermagor',
      204: 'Klagenfurt Land',
      205: 'Sankt Veit an der Glan',
      206: 'Spittal an der Drau',
      207: 'Villach Land',
      208: 'Völkermarkt',
      209: 'Wolfsberg',
      210: 'Feldkirchen'
    },
    Niederösterreich: {
      301: 'Krems an der Donau (Stadt)',
      302: 'Sankt Pölten (Stadt)',
      303: 'Waidhofen an der Ybbs (Stadt)',
      304: 'Wiener Neustadt (Stadt)',
      305: 'Amstetten',
      306: 'Baden',
      307: 'Bruck an der Leitha',
      308: 'Gänserndorf',
      309: 'Gmünd',
      310: 'Hollabrunn',
      311: 'Horn',
      312: 'Korneuburg',
      313: 'Krems (Land)',
      314: 'Lilienfeld',
      315: 'Melk',
      316: 'Mistelbach',
      317: 'Mödling',
      318: 'Neunkirchen',
      319: 'Sankt Pölten (Land)',
      320: 'Scheibbs',
      321: 'Tulln',
      322: 'Waidhofen an der Thaya',
      323: 'Wiener Neustadt (Land)',
      325: 'Zwettl'
    },
    Oberösterreich: {
      401: 'Stadt Linz',
      402: 'Stadt Steyr',
      403: 'Stadt Wels',
      404: 'Braunau',
      405: 'Eferding',
      406: 'Freistadt',
      407: 'Gmunden',
      408: 'Grieskirchen',
      409: 'Kirchdorf',
      410: 'Linz-Land',
      411: 'Perg',
      412: 'Ried',
      413: 'Rohrbach',
      414: 'Schärding',
      415: 'Steyr-Land',
      416: 'Urfahr-Umgebung',
      417: 'Vöcklabruck',
      418: 'Wels-Land'
    },
    Salzburg: {
      501: 'Salzburg (Stadt)',
      502: 'Hallein',
      503: 'Salzburg-Umgebung',
      504: 'Sankt Johann im Pongau',
      505: 'Tamsweg',
      506: 'Zell am See'
    },
    Steiermark: {
      601: 'Graz (Stadt)',
      603: 'Deutschlandsberg',
      606: 'Graz-Umgebung',
      610: 'Leibnitz',
      611: 'Leoben',
      612: 'Liezen',
      614: 'Murau',
      616: 'Voitsberg',
      617: 'Weiz',
      620: 'Murtal',
      621: 'Bruck-Mürzzuschlag',
      622: 'Hartberg-Fürstenfeld',
      623: 'Südoststeiermark'
    },
    Tirol: {
      701: 'Innsbruck-Stadt',
      702: 'Imst',
      703: 'Innsbruck-Land',
      704: 'Kitzbühel',
      705: 'Kufstein',
      706: 'Landeck',
      707: 'Lienz',
      708: 'Reutte',
      709: 'Schwaz'
    },
    Vorarlberg: {
      801: 'Bludenz',
      802: 'Bregenz',
      803: 'Dornbirn',
      804: 'Feldkirch'
    },
    Wien: {
      901: 'Wien  1., Innere Stadt',
      902: 'Wien  2., Leopoldstadt',
      903: 'Wien  3., Landstraße',
      904: 'Wien  4., Wieden',
      905: 'Wien  5., Margareten',
      906: 'Wien  6., Mariahilf',
      907: 'Wien  7., Neubau',
      908: 'Wien  8., Josefstadt',
      909: 'Wien  9., Alsergrund',
      910: 'Wien 10., Favoriten',
      911: 'Wien 11., Simmering',
      912: 'Wien 12., Meidling',
      913: 'Wien 13., Hietzing',
      914: 'Wien 14., Penzing',
      915: 'Wien 15., Rudolfsheim-Fünfhaus',
      916: 'Wien 16., Ottakring',
      917: 'Wien 17., Hernals',
      918: 'Wien 18., Währing',
      919: 'Wien 19., Döbling',
      920: 'Wien 20., Brigittenau',
      921: 'Wien 21., Floridsdorf',
      922: 'Wien 22., Donaustadt',
      923: 'Wien 23., Liesing'
    }
  };
}
