import type { MutationTree } from 'vuex';

export const state = () => ({
  id: null,
  step: 1,
  result: false,
  edit: false,
  showForm: false,
  ShowMissingOnly: false
});

export type evaluation = ReturnType<typeof state>;

export const mutations: MutationTree<evaluation> = {
  SET_STEP: (state, step: number) => (state.step = step),
  SET_EDIT: (state, edit: boolean) => (state.edit = edit),
  SET_SHOW: (state, show) => (state.showForm = show),
  SET_RESULT: (state, result: boolean) => (state.result = result),
  SET_SHOWMISSINGONLY: (state, ShowMissingOnly: boolean) =>
    (state.ShowMissingOnly = ShowMissingOnly)
};
