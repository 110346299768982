import { MutationTree } from 'vuex';

export const state = () => ({
  email: ''
});
export type EmailRegisters = ReturnType<typeof state>;

export const mutations: MutationTree<EmailRegisters> = {
  SET_EMAIL: (state: any, email: string) => (state.email = email)
};
