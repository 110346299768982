import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  stateFactory: true,
  preserveState: true,
  name: 'selectedFilter',
  namespaced: true
})
export default class selectedFilter extends VuexModule {
    filterName: string | null = null;

 

  @Mutation
  setSelectedFilter(value: string) {
    this.filterName = value;
  }

}
