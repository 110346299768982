export function ifDefinedOtherwise<T, U, V>(
  first: T,
  second: () => U,
  otherwise: V
): U | V {
  return first != null ? second() : otherwise;
}

/**
 * A function that returns the second argument if only
 * the first argument is not undefined or is null otherwise returns undefined.
 */
export function ifDefinedOtherwiseUndefined<T, U>(
  first: T,
  second: () => U
): U | undefined {
  return ifDefinedOtherwise(first, second, undefined);
}

export function ifDefinedOtherwiseNull<T, U>(
  first: T,
  second: () => U
): U | null {
  return ifDefinedOtherwise(first, second, null);
}

export function numberOrZero(value: number | undefined): number {
  return value || 0;
}

export function sum(...values: number[]): number {
  return values.reduce((acc, value) => acc + value, 0);
}

export function parseIntOr<T>(value: string, otherwise: T): T | number {
  const parsed = parseInt(value);
  return isNaN(parsed) ? otherwise : parsed;
}

/**
 * A function that returns the array if only
 * it is not empty otherwise returns undefined.
 * @param array
 */
export function ifArrayNotEmpty<T>(array: T[]): T[] | undefined {
  return array.length > 0 ? array : undefined;
}

export function ifArrayNotEmptyReturnArray<T>(array: T[]): T[] | undefined {
  return array.length > 0 ? array : null;
}

/**
 * Inline switch case function for mapping
 */
export function switchCaseMap<T, U>(value: T, cases: [T, U][]): U | undefined {
  for (const [key, result] of cases) {
    if (value === key) {
      return result;
    }
  }
  return undefined;
}
