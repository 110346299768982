export function zipWith<T, U, V>(a: T[], b: U[], f: (a: T, b: U) => V): V[] {
  const result = [];
  for (let i = 0; i < a.length; i++) {
    result.push(f(a[i], b[i]));
  }
  return result;
}

export function reverseItems<T = any>(items: T[][]) {
  return items.map((item) => item && item.slice().reverse());
}

/**
 * Selects n elements from array evenly distributed
 */
export function selectN<T>(array: T[], n: number, startIndex = 0): T[] {
  const result: T[] = [];
  if (n < 1) {
    return result;
  }
  const step = Math.floor(array.length / n);
  for (let i = 0; i < n; i++) {
    result.push(array[startIndex + i * step]);
  }

  return result;
}
