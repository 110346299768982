import { Plugin } from '@nuxt/types';
import { container } from 'tsyringe';

const accessor: Plugin = (ctx) => {
  container.register('store', {
    useValue: ctx.store
  });
};

export default accessor;
