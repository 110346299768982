/**
 * A decorator for debouncing methods in a class
 */
import { createDecorator } from 'vue-class-component';

export function Debounce(time: number) {
  return function(target: any, propertyKey: string, descriptor: PropertyDescriptor) {
    const originalMethod = descriptor.value;
    let timeout: any;
    descriptor.value = function(...args: any[]) {
      clearTimeout(timeout);
      timeout = setTimeout(() => originalMethod.apply(this, args), time);
    };

    createDecorator((options) => {
      const oldMethod = options.beforeDestroy;
      options.beforeDestroy = function() {
        if (oldMethod) {
          oldMethod.apply(this);
        }
        clearTimeout(timeout);
      };
    })(target, propertyKey);

    return descriptor;
  };
}
