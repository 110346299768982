import posthog from 'posthog-js';
import Vue from 'vue';
import * as Sentry from '@sentry/browser';

export default function ({ app: { router, store } }, inject) {
  // Init PostHog
  // console.log(window);
  posthog.init('phc_HD6iDjjv5Yn8dtieT15pPLuNqBHKK9z80WbfB2mvMv6', {
    api_host: 'https://rpph.ireen.com',
    capture_pageview: false,
    loaded: () => {
      store.$fire.auth?.currentUser?.email &&
        posthog.identify(store.$fire.auth?.currentUser?.email);

      Sentry.init({
        dsn: 'https://ebc7b95c9c471656eafcad21537f8c99@o4505778741968896.ingest.sentry.io/4505786957168640',
        integrations: [
          new posthog.SentryIntegration(
            posthog,
            'https://ebc7b95c9c471656eafcad21537f8c99@o4505778741968896.ingest.sentry.io/',
            '4505786957168640'
          )
        ],
        environment: !process.env.PRODUCTION ? 'development' : 'production',
        ignore: ['node_modules', '.nuxt/dist/client/img']
      });
    }
  });
  inject('posthog', posthog);

  router.afterEach((to) => {
    Vue.nextTick(() => {
      if (store.$fire.auth?.currentUser?.email) {
        posthog.identify(store.$fire.auth?.currentUser?.email);
      }
      posthog.capture('$pageview', {
        $current_url: to.fullPath
      });
    });
  });
}
