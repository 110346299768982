import { Module, Mutation, VuexModule } from 'vuex-module-decorators';

@Module({
  name: 'evaluationCurve',
  stateFactory: true,
  namespaced: true,
  preserveState: false
})
export default class EvaluationCurve extends VuexModule {
  price = null;
  persentage = 90;
  months = '12 Monat';
  pdfIndex = null;

  @Mutation
  setPrice(val: any) {
    this.price = val;
  }

  @Mutation
  setPersentage(val: any) {
    this.persentage = val;
  }

  @Mutation
  setMonths(val: any) {
    this.months = val;
  }

  @Mutation
  setCdfIndex(val: any) {
    this.pdfIndex = val;
  }
}
