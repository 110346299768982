import Vue from 'vue';
import * as VueGoogleMaps from 'vue2-google-maps';
export default ({ app }) => {
  Vue.use(VueGoogleMaps, {
    load: {
      key: app.$config.GOOGLE_MAPS_API_KEY,
      libraries: 'places'
    }
  });
}
