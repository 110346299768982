import { Plugin } from '@nuxt/types';
import type NuxtI18n from '@nuxtjs/i18n';

const i18n: Plugin = ({ app, store }, inject) => {
  // @ts-ignore
  store.commit('i18n/SET_LANG', app.i18n.locale as NuxtI18n.Locale);

  store.watch(
    state => state.locale,
    (locale) => {
      // @ts-ignore
      app.i18n.setLocale(locale);
    }
  );

  inject('getLang', () => store.state.i18n.locale);
};

export default i18n;
